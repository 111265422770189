import React from 'react';
// import useToggle from '../../Hooks/useToggle';
import BackToTop from '../BackToTop';
// import Drawer from '../Mobile/Drawer';
import FaqHomeOne from './FaqHomeOne';
import FeaturesHomeOne from './FeaturesHomeOne';
import FooterHomeOne from './FooterHomeOne';
import HeroHomeOne from './HeroHomeOne';
import HomeOneHeader from './HomeOneHeader';
import ServicesHomeOne from './ServicesHomeOne';
import WorkPartHomeEight from './WorkPartHomeEight';
import CounterArea from './CounterArea';
import HeroHomeSix from './HeroHomeSix';
import SignupHomeEight from './SignupHomeEight';

function HomeOne() {
    return (
        <>
            <HomeOneHeader />
            <HeroHomeOne />
            {/* Getting Started */}
            <WorkPartHomeEight />
            {/* Stats */}
            <CounterArea />
            {/* Designed to help */}
            <ServicesHomeOne />
            {/* All Features */}
            <FeaturesHomeOne />
            <FaqHomeOne />
            {/* Download App */}
            <HeroHomeSix />
            {/* Subscribe */}
            <SignupHomeEight />
            <FooterHomeOne />
            <BackToTop />
        </>
    );
}

export default HomeOne;
