import React from 'react';

function Content() {
    return (
        <>
            <section className="appie-hero-area mt-100">
                <div className="container">
                    <h2 className="text-center mb-4">Privacy-Policy</h2>
                    <div className="row">
                        <div className="col-md-10 offset-md-1 text-justify">
                            <p className="mb-3">
                                A privacy policy and security document for a mobile app that tracks
                                menstrual periods should include the following information:
                            </p>
                            <h5 className="mb-2">1.Information collection</h5>
                            <p className="mb-3">
                                Describe what information the app collects from users, including
                                personal information such as name, age, and email address, and data
                                generated by the app, such as menstrual cycle dates and symptoms.
                            </p>

                            <h5 className="mb-2">2.Use of information</h5>
                            <p className="mb-3">
                                Explain how the collected information will be used, including any
                                sharing or disclosure of the information with third parties.
                            </p>
                            <h5 className="mb-2">3.Data security</h5>
                            <p className="mb-3">
                                Describe the measures taken to protect the information collected by
                                the app, including any encryption or secure storage methods.
                            </p>
                            <h5 className="mb-2">4.User control</h5>
                            <p className="mb-3">
                                Explain the options available to users for controlling the
                                information that is collected, such as the ability to delete their
                                account and data.
                            </p>

                            <h5 className="mb-2">5.Compliance with laws</h5>
                            <p className="mb-3">
                                Include a statement confirming compliance with relevant laws and
                                regulations regarding privacy and data protection.
                            </p>

                            <h5 className="mb-2">6.Changes to the policy</h5>
                            <p className="mb-3">
                                Describe the process for updating the privacy policy and inform
                                users of any changes.
                            </p>

                            <h5 className="mb-2">7.Contact information</h5>
                            <p className="mb-3">
                                Include a way for users to contact the company with any questions or
                                concerns about their privacy.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Content;
