import React from 'react';

function ServicesHomeOne({ className }) {
    return (
        <section className={`appie-service-area pt-90 pb-100 ${className}`} id="service">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-6">
                        <div className="appie-section-title text-center">
                            <h3 className="appie-title">
                                Designed to help <br /> Women in their strongest time.
                            </h3>
                            <p>
                                The app give all information and tips to help in your period days.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="200ms"
                        >
                            <div className="icon">
                                <i className="fas fa-dumbbell text-white fa-lg" />
                                <span>1</span>
                            </div>
                            <h4 className="appie-title">Yoga</h4>
                            <p>Yoga is highly recommended for women in their adolescence.</p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="400ms"
                        >
                            <div className="icon">
                                <i className="fas fa-clinic-medical text-white fa-lg" />
                                <span>2</span>
                            </div>
                            <h4 className="appie-title">Doctor Support</h4>
                            <p>
                                Book appointment with minimum wait-time & verified Doctor details.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="600ms"
                        >
                            <div className="icon">
                                <i className="fas fa-utensils-alt text-white fa-lg" />
                                <span>3</span>
                            </div>
                            <h4 className="appie-title">Food</h4>
                            <p>
                                lists of nutrients rich foods which can be used to serve different
                                wellness purpose.
                            </p>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6">
                        <div
                            className="appie-single-service text-center mt-30 item-2 wow animated fadeInUp"
                            data-wow-duration="2000ms"
                            data-wow-delay="800ms"
                        >
                            <div className="icon">
                                <i className="fas fa-heartbeat text-white fa-lg" />
                                <span>4</span>
                            </div>
                            <h4 className="appie-title">Hygiene</h4>
                            <p>Intimate hygiene is so much more than just showering regularly.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ServicesHomeOne;
