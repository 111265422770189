import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';

function FooterHomeOne({ className }) {
    return (
        <>
            <section className={`appie-footer-area ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-about-widget">
                                <div className="logo">
                                    <a href="#">
                                        <img src={logo} alt="" />
                                    </a>
                                </div>
                                <p>Giving hearts is always there to help you.</p>

                                <div className="social mt-30">
                                    <ul>
                                        <li>
                                            <a
                                                href="https://www.facebook.com/kookyinfomedia"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-facebook-f" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://twitter.com/KookyInfomedia"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.instagram.com/kookyinfomedia"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-instagram" />
                                            </a>
                                        </li>
                                        <li>
                                            <a
                                                href="https://www.linkedin.com/company/kooky-infomedia/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <i className="fab fa-linkedin-in" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-navigation">
                                <h4 className="title">Helpful Links</h4>
                                <ul>
                                    <li>
                                        <Link to="/about-us">Download App</Link>
                                    </li>
                                    <li>
                                        <Link to="/privacy-policy">Privacy Policy</Link>
                                    </li>
                                    <li>
                                        <Link to="/terms">Terms & Conditions</Link>
                                    </li>
                                    <li>
                                        <Link to="/news">FAQ</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="footer-widget-info">
                                <h4 className="title">Get In Touch</h4>
                                <ul>
                                    <li>
                                        <a href="mailto:help.givingheart@gmail.com">
                                            <i className="fal fa-envelope" />
                                            help.givingheart@gmail.com
                                        </a>
                                    </li>
                                    <li>
                                        <a href="tel:+919358847163">
                                            <i className="fal fa-phone" /> +91 9358847163
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://goo.gl/maps/H6h1RVzzA1QzxzB89">
                                            <i className="fal fa-map-marker-alt" /> 442 Belle Terre
                                            St Floor 7, San Francisco, AV 4206
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-copyright d-flex align-items-center justify-content-between pt-35">
                                <div className="copyright-text">
                                    <p>Copyright © 2022 Giving Hearts. All rights reserved.</p>
                                </div>
                                <div className="apps-download-btn">
                                    <p className="tw-mb-0 text-black poppins-medium">
                                        Website developed with &nbsp;
                                        <i className="fa fa-heart font-18" aria-hidden="true"></i>
                                        &nbsp; by
                                        <a
                                            href="https://www.kookyinfomedia.com/"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            &nbsp; <b> Kooky Infomedia </b>
                                        </a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FooterHomeOne;
