import React, { useState } from 'react';

function FaqHomeOne({ className }) {
    const [showQues, setQues] = useState(1);
    const openQuestion = (value) => {
        setQues(value);
    };
    return (
        <>
            <section id="faq" className={`appie-faq-area pt-100 pb-95 ${className || ''}`}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="appie-section-title text-center">
                                <h3 className="appie-title">Frequently asked questions</h3>
                                <p>
                                    Browse through our FAQ section to find answers to common
                                    questions about how to use our app, how to track your menstrual
                                    cycle, and how to manage your reproductive health.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-6">
                            <div
                                className="faq-accordion wow fadeInRight mt-30"
                                data-wow-duration="1500ms"
                            >
                                <div
                                    className="accrodion-grp animated fadeIn faq-accrodion wow"
                                    data-wow-duration="1500ms"
                                    data-grp-name="faq-accrodion"
                                >
                                    <div
                                        onClick={() => openQuestion(1)}
                                        className={`accrodion ${showQues === 1 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Should we exercise during the period?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 1 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Yes, you can follow a light exercise routine
                                                        during the period. Avoid doing complex
                                                        exercises and yoga asanas during these days.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(2)}
                                        className={`accrodion ${showQues === 2 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>
                                                    Why do I have to poop so often when I have my
                                                    period?
                                                </h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 2 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        It may happen to a few that they poop too
                                                        often during periods. Experts say that is
                                                        because along with abdominal cramps our
                                                        intestines may also contract to result in
                                                        this condition.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(3)}
                                        className={`accrodion ${showQues === 3 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>How much blood do I lose during my period?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 3 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Most girls lose about 1/4 cup of menstrual
                                                        fluid during their periods (mostly in the
                                                        first few days). Not to worry, though — your
                                                        body makes up for it.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div
                                className="faq-accordion wow fadeInRight mt-30"
                                data-wow-duration="1500ms"
                            >
                                <div
                                    className="accrodion-grp animated fadeIn faq-accrodion wow"
                                    data-wow-duration="1500ms"
                                    data-grp-name="faq-accrodion"
                                >
                                    <div
                                        onClick={() => openQuestion(4)}
                                        className={`accrodion ${showQues === 4 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Should one bathe during periods?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 4 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Yes, bathing is crucial during periods. It
                                                        keeps you clean and away from disease. Hot
                                                        water bath also reduces period cramps.
                                                        Scientifically hair wash is asked to avoid
                                                        because it cools down your entire body,
                                                        hence necessary blood flow will reduce
                                                        resulting in blood clots.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(5)}
                                        className={`accrodion ${showQues === 5 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>Why is the blood brown sometimes?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 5 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        The hue depends on how heavy your flow is.
                                                        "It can take time for the blood to pass out
                                                        of the uterus, through the cervix, and out
                                                        the vagina," Dr. Yamaguchi says. "Red blood
                                                        is fresher, but as time passes, it will turn
                                                        a darker color." So as you get into your
                                                        slower-flow days, your blood is more likely
                                                        to be brownish.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(6)}
                                        className={`accrodion ${showQues === 6 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>How to reduce period pains?</h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 6 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Menstrual cramps and discomfort are common
                                                        symptoms experienced during periods. This is
                                                        due to the shedding of the inner uterine
                                                        lining. To reduce period pain all you have
                                                        to do is keep a tab on your diet. It is
                                                        advised to eat healthily and drink lots of
                                                        fluids during periods. Also, restrict the
                                                        intake of foods such as tea, coffee,
                                                        chocolates, salty foods, and red meat which
                                                        can increase the risk of stomach woes.
                                                        Taking a pain reliever is not a good idea
                                                        every time you have period pain. Hence, it
                                                        is advised to use a heating pad which
                                                        improves blood flow and helps ease the pain.
                                                        You can also take a warm water bath to
                                                        reduce pain during menstruation.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        onClick={() => openQuestion(7)}
                                        className={`accrodion ${showQues === 7 ? 'active' : ''}`}
                                    >
                                        <div className="accrodion-inner">
                                            <div className="accrodion-title">
                                                <h4>
                                                    Can we eat sour things like Tamarind during
                                                    periods?
                                                </h4>
                                            </div>
                                            <div
                                                className="accrodion-content"
                                                style={{
                                                    display: showQues === 7 ? 'block' : 'none',
                                                }}
                                            >
                                                <div className="inner">
                                                    <p>
                                                        Yes, there is no harm to eat sour food items
                                                        during this period. To maintain your health
                                                        avoid overeating any specific food item.
                                                        Avoid junk food and alcohol.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default FaqHomeOne;
