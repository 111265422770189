import React from 'react';

function Content() {
    return (
        <>
            <section className="appie-hero-area mt-100">
                <div className="container">
                    <h2 className="text-center mb-4">Terms & Conditions</h2>
                    <div className="row">
                        <div className="col-md-10 offset-md-1 text-justify">
                            <h5 className="mb-2">1.Introduction</h5>

                            <p className="mb-3">
                                These terms and conditions (&ldquo;Terms&rdquo;) govern the use of
                                the menstruation tracking app (&ldquo;App&rdquo;) provided by us,
                                Giving Heart Foundation. By accessing or using the App, you agree to
                                be bound by these Terms. If you do not agree to these Terms, you
                                must not use the App.
                            </p>

                            <h5 className="mb-2">2.Use of the App</h5>

                            <ol style={{ listStyleType: 'disc' }} className="mb-1 ml-4">
                                <li className="mb-2">
                                    The App is intended for use by girls and women to track their
                                    menstrual cycle, log symptoms and other related information.
                                </li>
                                <li className="mb-2">
                                    You must not use the App for any purpose that is illegal or
                                    prohibited by these Terms.
                                </li>
                                <li className="mb-2">
                                    You must not use the App to transmit any harmful, offensive, or
                                    malicious content.
                                </li>
                                <li className="mb-2">
                                    You must not use the App to impersonate any person or entity, or
                                    falsely state or otherwise misrepresent your affiliation with a
                                    person or entity.
                                </li>
                                <li className="mb-2">
                                    You must not use the App to interfere with or disrupt the App or
                                    servers or networks connected to the App, or disobey any
                                    requirements, procedures, policies, or regulations of networks
                                    connected to the App.
                                </li>
                            </ol>

                            <h5 className="mb-2">3.Ownership and Intellectual Property</h5>

                            <ol style={{ listStyleType: 'disc' }} className="mb-2 ml-4">
                                <li className="mb-2">
                                    The App and all intellectual property rights in the App,
                                    including but not limited to any text, graphics, logos, images,
                                    and software, are owned by us or our licensors.
                                </li>
                                <li className="mb-2">
                                    You must not copy, reproduce, distribute, publish, display,
                                    perform, modify, create derivative works, transmit, or in any
                                    way exploit any part of the App without our prior written
                                    consent.
                                </li>
                            </ol>

                            <h5 className="mb-2">4.Disclaimer of Warranties</h5>

                            <ol style={{ listStyleType: 'disc' }} className="mb-1 ml-4">
                                <li className="mb-2">
                                    The App is provided on an &ldquo;as is&rdquo; and &ldquo;as
                                    available&rdquo; basis. We make no representations or warranties
                                    of any kind, express or implied, as to the operation of the App
                                    or the information, content, materials, or products included on
                                    the App.
                                </li>
                                <li className="mb-2">
                                    We do not warrant that the App will be uninterrupted or
                                    error-free, and we will not be liable for any interruptions or
                                    errors.
                                </li>
                            </ol>

                            <h5 className="mb-2">5.Limitation of Liability</h5>

                            <p className="mb-3">
                                To the fullest extent permitted by law, we will not be liable for
                                any direct, indirect, incidental, special, or consequential damages
                                arising out of or in connection with the use of the App or the
                                inability to use the App, including but not limited to damages for
                                loss of profits, use, data, or other intangible losses.
                            </p>

                            <h5 className="mb-2">6.Governing Law</h5>

                            <p className="mb-3">
                                These Terms will be governed by and construed in accordance with the
                                laws of India , without giving effect to any principles of conflicts
                                of law.
                            </p>

                            <h5 className="mb-2">7.Changes to the Terms</h5>

                            <p className="mb-3">
                                We reserve the right to change these Terms at any time. Your
                                continued use of the App following any changes to these Terms will
                                mean you accept those changes.
                            </p>

                            <h5 className="mb-2">8.Contact Us</h5>

                            <p className="mb-3">
                                If you have any questions about these Terms or the App, please
                                contact us at help &nbsp
                                <a href="mailto:help.givingheart@gmail.com">
                                    help.givingheart@gmail.com
                                </a>
                                &nbsp;, phone -.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Content;
