import React from 'react';
import { Link } from 'react-router-dom';

function Navigation() {
    return (
        <>
            <ul>
                <li>
                    <Link to="/">Home</Link>
                </li>
                <li>
                    <Link to="/privacy-policy">Privacy-Policy</Link>
                </li>

                <li>
                    <Link to="/terms">Terms & Conditions</Link>
                </li>
                <li>
                    <a href="/#faq">FAQ</a>
                </li>
            </ul>
        </>
    );
}

export default Navigation;
